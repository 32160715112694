import Modal from '@core-ui/modal';
import { COLOURS } from '@core-ui/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Avatar from '@mui/material/Avatar';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { GET } from '@/api/chatApi';
import { formatTime } from '@/app/utils/dates';
import ChatImagesCarousel from '@/components/ImageCarousel';
import { useAppSelector } from '@/hooks/useAppSelector';
import { FC, useEffect, useState } from 'react';

import selector from './selector';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const noThumbnail = '400';

const ChatImageCarousel: FC<IProps> = (props) => {
  const { opened, onClose } = props;

  const [files, setFiles] = useState<string[]>([]);
  const [imgCarouselIdx, setImgCarouselIdx] = useState(0);
  const { user, time, files_ids } = useAppSelector(selector);

  useEffect(() => {
    const getPreviews = async (file_id: string, idx: number) => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent) => {
        setFiles((filesArray) => {
          const temp = [...filesArray];
          // @ts-ignore
          temp[idx] = e.target.result;

          return temp;
        });
      };
      try {
        const res = (await GET(`/api/v4/files/${file_id}/preview`, {}, { responseType: 'blob' })) as Blob;
        reader.readAsDataURL(res);
      } catch (e) {
        setFiles((f) => [...f, noThumbnail]);
      }
    };
    files_ids?.map(getPreviews);
  }, []);

  return (
    <Modal open={opened} onClose={onClose} sx={{ height: '100%' }} maxWidth="lg">
      <DialogContent sx={{ height: '100%', position: 'relative', minWidth: '600px' }}>
        <ChatImagesCarousel images={files} getCurrentIndex={setImgCarouselIdx} />
        <Grid
          container
          sx={{
            position: 'absolute',
            bottom: '32px',
            backgroundColor: '#070708',
            left: '50%',
            marginLeft: 'auto',
            marginRight: 'auto',
            transform: 'translate(-50%, 0%)',
            width: 'auto',
            borderRadius: '4px',
          }}
          padding="14px"
          wrap="nowrap"
          columnGap={2}
        >
          <Avatar variant="rounded" alt={user?.id} />

          <Grid item xs={8}>
            <Typography color={COLOURS.HUE_COCONUT.HUE_100}>{user?.first_name || user?.last_name}</Typography>
            <Typography color={COLOURS.HUE_COCONUT.HUE_100} variant="body2">
              {time && formatTime(time, { dateFormat: 'd MMM yyyy' })}
            </Typography>
          </Grid>
          {/* <IconButton sx={{ color: COLOURS.HUE_COCONUT.HUE_100 }}>
            <ZoomOutIcon />
          </IconButton>
          <IconButton sx={{ color: COLOURS.HUE_COCONUT.HUE_100 }}>
            <ZoomInIcon />
          </IconButton> */}

          <a href={`${files[imgCarouselIdx]}`} target="_blank" rel="noreferrer" download="file.png">
            <IconButton sx={{ color: COLOURS.HUE_COCONUT.HUE_100 }}>
              <FileDownloadIcon />
            </IconButton>
          </a>
        </Grid>
      </DialogContent>
    </Modal>
  );
};

export default ChatImageCarousel;
